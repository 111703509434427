import { DefaultTheme } from 'styled-components';
import React, { useEffect } from 'react';
import { darkTheme } from 'styled/theme';
import ProjectDetail from 'components/project-detail';
import Heading from 'components/heading';
import LazyLoad from 'react-lazyload';
import Image from 'components/image';
import Separator from 'components/separator';
import { AnimationDirection } from 'components/FadeInSection/styles';
import { useDocumentTitlePortfolio } from 'utils/pageUtils';
import images from './images';

// We define the props that will receive the setTheme
type Props = {
  setTheme: React.Dispatch<React.SetStateAction<DefaultTheme>>;
};

export default function Erres(props: Props): JSX.Element {
  useEffect(() => {
    useDocumentTitlePortfolio('/erres/ 🌽', 'Branding');
  });
  return (
    <ProjectDetail heading='/erres/ 🌽' categories={['Branding']} subheading='Bayer, realizado en KREAB &nbsp;|&nbsp; 2021' theme={darkTheme} setTheme={props.setTheme}>
      <Image imgSrc={images[0].src} imgAlt={images[0].title} disableLazyLoad={true}/>
      <Heading
        alignment="left"
        className="project-details__body__heading"
        label="Punto de partida"
        level={2}
        marginBottom={1}
      />
      <div>
        <p>
        /erres/ es una iniciativa impulsada por Bayer, que nace con el objetivo de analizar
        cómo el desarrollo rural y la vertebración del territorio pueden contribuir a la mejora de la salud,
        tanto desde la perspectiva ambiental como personal.
        </p>
      </div>
      <Heading
        alignment="left"
        className="project-details__body__heading"
        label="Requerimientos del proyecto"
        level={2}
        marginBottom={1}
      />
      <div>
        <ul>
          <li>Conceptualización de un nombre que represente la iniciativa.</li>
          <li>Diseño de logotipo e identidad gráfica.</li>
          <li>Maquetación del informe realizado por el grupo de expertos.</li>
        </ul>
      </div>
      <br/>
      <LazyLoad height="50vh" offset={100}>
        <Image imgSrc={images[1].src} imgAlt={images[1].title} disableLazyLoad={true}
          animationDirection={AnimationDirection.Up}/>
      </LazyLoad>
      <Heading
        alignment="left"
        className="project-details__body__heading"
        label="Naming"
        level={2}
        marginBottom={1}
      />
      <div>
        <p>
        A partir del fonema /erres/ creamos un juego de palabras y lo convertimos en un acrónimo:
        /erres/, la cual contiene los aspectos más importantes del proyecto:
          <strong> Expertos repensando lo rural para una España saludable y sostenible</strong>.
        </p>
      </div>
      <br/>
      <LazyLoad height="50vh" offset={100}>
        <Image imgSrc={images[2].src} imgAlt={images[2].title} disableLazyLoad={true}
          animationDirection={AnimationDirection.Up}/>
      </LazyLoad>
      <Heading
        alignment="left"
        className="project-details__body__heading"
        label="Paleta de colores"
        level={2}
        marginBottom={1}
      />
      <div>
        <p>
        El color principal de la marca representa la unión de los aspectos
        esenciales del proyecto: salud y sostenibilidad.
        </p>
        <p>
        La paleta secundarias de /erres/ está compuesta por colores vibrantes,
        aportando un aire digital al proyecto.
        </p>
      </div>
      <br/>
      <LazyLoad height="50vh" offset={100}>
        <Image imgSrc={images[4].src} imgAlt={images[4].title} animationDirection={AnimationDirection.Up}
          disableLazyLoad={true}/>
      </LazyLoad>
      <Heading
        alignment="left"
        className="project-details__body__heading"
        label="Elementos gráficos"
        level={2}
        marginBottom={1}
      />
      <div>
        <p>
        Las barras serán el elemento gráfico principal del proyecto.
        A nivel lingüístico las barras contendrán los aspectos más destacados del mensaje.
        Mientras que a nivel gráfico funcionarán como contenedor de imágenes.
        </p>
      </div>
      <div className="project-details__body__images">
        <Image imgSrc={images[5].src} imgAlt={images[5].title} animationDirection={AnimationDirection.Up}/>
        <Separator marginTop={1} marginBottom={1}></Separator>
        <Image imgSrc={images[6].src} imgAlt={images[6].title} animationDirection={AnimationDirection.Up}/>
        <Separator marginTop={1} marginBottom={1}></Separator>
        <Image imgSrc={images[7].src} imgAlt={images[7].title} animationDirection={AnimationDirection.Up}/>
        <Separator marginTop={1} marginBottom={1}></Separator>
        <Image imgSrc={images[8].src} imgAlt={images[8].title} animationDirection={AnimationDirection.Up}/>
        <Separator marginTop={1} marginBottom={1}></Separator>
        <Image imgSrc={images[9].src} imgAlt={images[9].title} animationDirection={AnimationDirection.Up}/>
        <Separator marginTop={1} marginBottom={1}></Separator>
        <Image imgSrc={images[10].src} imgAlt={images[10].title} animationDirection={AnimationDirection.Up}/>
      </div>
    </ProjectDetail>
  );
}
