import PageWrapper from 'components/pageWrapper';
import { DefaultTheme } from 'styled-components';
import { useEffect } from 'react';
import Container from 'components/container';
import { useDocumentTitle } from 'utils/pageUtils';
import Heading from 'components/heading';
import { publish } from 'utils/events';
import { colorTheme } from '../../styled/theme';
import { MainStyle } from './styles';

// We define the props that will receive the setTheme
type Props = {
  setTheme: React.Dispatch<React.SetStateAction<DefaultTheme>>;
};

export default function PoliticaDeCookies(props: Props): JSX.Element {
  useEffect(() => {
    useDocumentTitle('Política de Cookies');
    props.setTheme(colorTheme);
    publish('pageChange', { name: 'politica-de-cookies' });
  });
  return (
    <PageWrapper heightAuto={true} cssStyling={MainStyle} >
      <Container flexDirection="column">
        {/* Contenido */}
        <Container flexDirection="column" align="flex-start" responsive={false} spaceGap={0}>
          <Heading level={1} label="Política de Cookies" alignment="left" marginTop={2}/>

          <p>En este sitio web utilizamos cookies de origen proporcionadas por <strong>Google Analytics</strong>.
          Estas cookies nos ayudan a recopilar información anónima sobre cómo los visitantes utilizan nuestro sitio,
          con el objetivo de mejorar su funcionamiento y contenido.</p>

          <Heading level={2} label="¿Qué son las cookies?" alignment="left" />
          <p>Las cookies son pequeños archivos de texto que se almacenan en tu dispositivo
            (ordenador, smartphone, tableta) cuando visitas un sitio web. Estas permiten que el sitio recuerde
            tus preferencias y mejoran tu experiencia como usuario.</p>

          <Heading level={2} label="¿Qué tipo de cookies utilizamos?" alignment="left" />
          <p>Solo utilizamos cookies de análisis proporcionadas por Google Analytics.
            Estas cookies recopilan información de manera anónima y no identifican a los usuarios de forma directa.</p>

          <Heading level={3} label="Cookies utilizadas por Google Analytics" alignment="left" />
          <table>
            <thead>
              <tr>
                <th>Nombre de la cookie</th>
                <th>Propósito</th>
                <th>Duración</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>_ga</td>
                <td>Identifica a los usuarios únicos asignándoles un identificador aleatorio.</td>
                <td>2 años</td>
              </tr>
              <tr>
                <td>_ga_P2GGZ3KZYB</td>
                <td>Se usa para mantener el estado de la sesión.</td>
                <td>2 años</td>
              </tr>
            </tbody>
          </table>

          <Heading level={2} label="¿Qué datos recopilan estas cookies?" alignment="left" />
          <p>Las cookies de Google Analytics recopilan información como:</p>
          <ul>
            <li>El número de visitantes al sitio web.</li>
            <li>Las páginas visitadas.</li>
            <li>El tiempo que los usuarios pasan en el sitio.</li>
            <li>El tipo de dispositivo, navegador y sistema operativo.</li>
          </ul>
          <p><strong>Nota:</strong> No recopilamos información personal que pueda identificarte directamente,
          como nombres, direcciones o correos electrónicos.</p>

          <Heading level={2} label="¿Cómo gestionar o desactivar las cookies?" alignment="left" />
          <p>Puedes aceptar o rechazar las cookies desde el banner que aparece al visitar nuestro
            sitio web por primera vez. También puedes gestionar las cookies configurando tu navegador.
            A continuación, te proporcionamos enlaces a las instrucciones de los navegadores más populares:</p>
          <ul>
            <li><a href="https://support.google.com/chrome/answer/95647?hl=es" target="_blank">Google Chrome</a></li>
            <li><a href="https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias" target="_blank">Mozilla Firefox</a></li>
            <li><a href="https://support.apple.com/es-es/guide/safari/sfri11471/mac" target="_blank">Safari</a></li>
            <li><a href="https://support.microsoft.com/es-es/microsoft-edge/eliminar-cookies-en-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09" target="_blank">Microsoft Edge</a></li>
          </ul>
          <p>Si deseas deshabilitar únicamente las cookies de Google Analytics, puedes instalar el <a href="https://chrome.google.com/webstore/detail/google-analytics-opt-out/fllaojicojecljbmefodhfapmkghcbnh?hl=es" target="_blank">Complemento de Inhabilitación para Navegadores de Google Analytics</a>.</p>

          <Heading level={2} label="Cambios en nuestra política de cookies" alignment="left" />
          <p>Nos reservamos el derecho a actualizar esta política en cualquier momento para reflejar cambios
            en el uso de cookies o ajustes legales. Te recomendamos que revises esta página periódicamente
            para mantenerte informado.</p>
        </Container>
      </Container>
    </PageWrapper>
  );
}
