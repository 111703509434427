import { css } from 'styled-components';
import { FontSize } from 'styled/constants';

// eslint-disable-next-line import/prefer-default-export
export const MainStyle = css`
  table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
  }
  th, td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  th {
    background-color: #f8f9fa;
  }
  p {
    margin: 1rem 0;
  }
  h1 {
    font-size: 2rem;
  }
  h2 {
    font-size: ${FontSize.ml.valueOf()}rem;
  }
  h3 {
    font-size: ${FontSize.m.valueOf()}rem;
  }
`;
