import styled from 'styled-components';
import MediaBreakpoints from '../../styled/mediaBreakpoints';

// eslint-disable-next-line import/prefer-default-export
export const StyledButton = styled.div`
   bottom: 1rem;
   left: 1rem;
   color: ${(props) => props.theme.primary.textColor};
   cursor: pointer;
   display: none;
   font-size: 1.75rem;
   transition: opacity 0.5s ease;
   position: fixed;
   width: auto;
   z-index: 1;
   @media ${MediaBreakpoints.largeUp} {
     display: block;
   }
   
`;
