import {
  Dispatch, SetStateAction, useEffect, useState,
} from 'react';
import Burger from 'components/structure/Header/Burger';
import { EventData, subscribe } from 'utils/events';
import {
  Nav as StyledNav, NavLink, StyledLateralNav, BaseLink,
} from './styles';

interface Props {
  menuOpen: boolean;
  setMenuOpen: Dispatch<SetStateAction<boolean>>;
}
function Nav(props: Props): JSX.Element {
  const menuId = 'main-menu';
  const [activePage, setActivePage] = useState('');
  const { menuOpen, setMenuOpen } = props;
  function pageChangeHandler(event: CustomEvent<EventData>) {
    // navigate(event.detail.name);
    setActivePage(event.detail.name);
  }
  const [visible, setVisible] = useState(false);
  const onScroll = () => {
    // This will calculate how many pixels the page is vertically
    const winScroll = document.documentElement.scrollTop;
    // This is responsible for subtracticing the total height of the page - where the users page is scrolled to
    const height = document.documentElement.scrollHeight
      - document.documentElement.clientHeight;

    // This will calculate the final total of the percentage of how much the user has scrolled.
    const scrolledPosition = (winScroll / height) * 100;
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 450 && scrolledPosition > 10) {
      setVisible(true);
    } else if (scrolled <= 450 || scrolledPosition < 10) {
      setVisible(false);
    }
  };
  const handleCookies = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    setMenuOpen(!menuOpen);
    const banner = document.getElementById('cookie-banner');
    if (banner) banner.style.display = 'block';
  };

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);
  useEffect(() => {
    subscribe('pageChange', (pageChangeHandler) as EventListener);
  }, []);

  return (
    <>
      {/* Desktop */}
      <StyledNav className={'desktop-nav'}>
        <ul className="nav-list">
          <li>
            <NavLink to="/portfolio" className={activePage === 'portfolio' ? 'active' : ''} state={ { internalRedirect: true } }>Portfolio</NavLink>
          </li>
          <li>
            <NavLink to="/about" className={activePage === 'about' ? 'active' : ''}>Sobre mí</NavLink>
          </li>
        </ul>
      </StyledNav>
      {/* Desktop  Lateral */}
      <StyledLateralNav className={'desktop-nav'} style={{ opacity: visible ? 1 : 0, visibility: visible ? 'visible' : 'hidden' }} >
        <ul className="nav-list">
          <li>
            <BaseLink to="/portfolio" className={activePage === 'portfolio' ? 'active' : ''} state={ { internalRedirect: true } }>Portfolio</BaseLink>
          </li>
          <li>
            <BaseLink to="/about" className={activePage === 'about' ? 'active' : ''}>Sobre mí</BaseLink>
          </li>
        </ul>
      </StyledLateralNav>
      {/* Mobile */}
      <StyledNav
        className={`mobile-nav ${menuOpen ? 'opened' : 'closed'}`}
        open={menuOpen}
      >
        <ul className="nav-list">
          <li>
            <BaseLink to="/portfolio" className={activePage === 'portfolio' ? 'active' : ''} onClick={() => setMenuOpen(!menuOpen)} state={ { internalRedirect: true } }>
              Portfolio
            </BaseLink>
          </li>
          <li>
            <BaseLink to="/about" className={activePage === 'about' ? 'active' : ''} onClick={() => setMenuOpen(!menuOpen)}>
              Sobre mí
            </BaseLink>
          </li>
          <li>
            <BaseLink to="/politica-de-cookies" className={activePage === 'politica-de-cookies' ? 'active' : ''} onClick={(event) => handleCookies(event)}>
              Cookies
            </BaseLink>
          </li>
        </ul>
      </StyledNav>
      <Burger open={menuOpen} setOpen={setMenuOpen} aria-controls={menuId} />
    </>
  );
}

Nav.defaultProps = {
  className: '',
};

export default Nav;
