/* eslint-disable max-len */
import { DefaultTheme } from 'styled-components';
import React, { useEffect } from 'react';
import { purpleTheme } from 'styled/theme';
import ProjectDetail from 'components/project-detail';
import Heading from 'components/heading';
import LazyLoad from 'react-lazyload';
import Image from 'components/image';
import Separator from 'components/separator';
import { AnimationDirection } from 'components/FadeInSection/styles';
import { useDocumentTitlePortfolio } from 'utils/pageUtils';
import Container from 'components/container';
import images from './images';

// We define the props that will receive the setTheme
type Props = {
  setTheme: React.Dispatch<React.SetStateAction<DefaultTheme>>;
};

export default function Camalotal(props: Props): JSX.Element {
  useEffect(() => {
    useDocumentTitlePortfolio('Camalotal 🌸', 'Branding');
  });
  return (
    <ProjectDetail heading='Camalotal 🌸' categories={['Branding']} subheading='Camalotal &nbsp;|&nbsp; 2024' theme={purpleTheme} setTheme={props.setTheme}>
      <Image imgSrc={images[0].src} imgAlt={images[0].title} disableLazyLoad={true}/>
      {/* Columns wrapper */}
      <Container flexDirection="row" align="flex-start" responsive={true} columnGap={2.5} rowGap={0}>
        {/* Columna Izquierda */}
        <Container
          flexDirection="column"
          justifyContent="flex-start"
          align='flex-start'
          rowGap={0}>
          <Heading
            alignment="left"
            className="project-details__body__heading"
            label="Punto de partida"
            level={2}
          />
          <div>
            <p>
              <strong>Camalotal es una marca de cremas naturales cuyo nombre hace referencia a las raíces litoraleñas de su creadora. </strong>
              Para Sofía, era importante que la naturaleza se vea reflejada en el nombre y la identidad visual de su marca, ya que sus productos
              están basados en la cosmética herbal, y sus propiedades se deben a la combinación consciente de principios activos de distintas plantas.
            </p>
            <br/>
            <p>
              Los camalotes son una planta típica del paisaje del litoral argentino.
              La intención de Sofía es tener presente la imagen de estas plantas acuáticas viajando por el río.
              Se pregunta qué traerán escondido entre sus gruesas hojas, qué dejarán en su paso por ese lugar, qué se llevarán consigo y hasta dónde llegarán.
            </p>
          </div>
        </Container>
        {/* Columna Derecha */}
        <Container
          flexDirection="column"
          justifyContent="flex-start"
          align='flex-start'
          rowGap={0}>
          <Heading
            alignment="left"
            className="project-details__body__heading"
            label="Requerimientos del proyecto"
            level={2}
          />
          <div>
            <p>
              Antes de comenzar a diseñar, fue necesario clarificar, ordenar y jerarquizar las necesidades de la marca, definir los valores y el arquetipo de Camalotal.
            </p>
            <br/>
            <p>
              Al finalizar esta primera etapa, comenzamos con el proceso de diseño, que incluyó el diseño de logotipo, el manual de marca,
              con un fuerte enfoque en redes sociales, y el packaging de los productos.
            </p>
          </div>
        </Container>
      </Container>
      <br/>
      <br/>
      <LazyLoad height="50vh" offset={100}>
        <Image imgSrc={images[1].src} imgAlt={images[1].title} disableLazyLoad={true}
          animationDirection={AnimationDirection.Up}/>
      </LazyLoad>
      {/* Columns wrapper */}
      <Container flexDirection="row" align="flex-start" responsive={true} columnGap={2.5} rowGap={0}>
        {/* Columna Izquierda */}
        <Container
          flexDirection="column"
          justifyContent="flex-start"
          align='flex-start'
          rowGap={0}>
          <Heading
            alignment="left"
            className="project-details__body__heading"
            label="Camalotal es simple"
            level={2}
          />
          <div>
            <p>
              Ponemos el foco en lo relevante. Por eso, optamos por un logotipo que ponga en valor el nombre de la marca y represente su simplicidad.
            </p>
          </div>
          <Heading
            alignment="left"
            className="project-details__body__heading"
            label="Camalotal es cuidar"
            level={2}
          />
          <div>
            <p>
            Tomando como inspiración las farmacias antiguas, hemos seleccionado una tipografía script con mucho carácter,
            conformada por trazos rectos, que hablan de la seriedad de la marca, pero también trazos orgánicos que nos recuerdan a la naturaleza.
            </p>
          </div>
        </Container>
        {/* Columna Derecha */}
        <Container
          flexDirection="column"
          justifyContent="flex-start"
          align='flex-start'
          rowGap={0}>
          <Heading
            alignment="left"
            className="project-details__body__heading"
            label="Camalotal es natural"
            level={2}
          />
          <div>
            <p>
              Los productos de Camalotal se producen a base de plantas y elementos de la naturaleza, por eso hemos elegido una tipografía con líneas
              curvas que nos recuerda a la naturaleza. También incluimos un glifo en el centro de la letra “O”, que hace referencia a la flor del camalote,
              reforzando la importancia de la naturaleza en este proyecto.
            </p>
          </div>
        </Container>
      </Container>
      <br/>
      <br/>
      <LazyLoad height="50vh" offset={100}>
        <Image imgSrc={images[2].src} imgAlt={images[2].title} disableLazyLoad={true}
          animationDirection={AnimationDirection.Up}/>
      </LazyLoad>
      {/* Columns wrapper */}
      <Container flexDirection="row" align="flex-start" responsive={true} columnGap={2.5} rowGap={0}>
        {/* Columna Izquierda */}
        <Container
          flexDirection="column"
          justifyContent="flex-start"
          align='flex-start'
          rowGap={0}>
          <Heading
            alignment="left"
            className="project-details__body__heading"
            label="Elementos gráficos"
            level={2}
            marginBottom={1}
          />
          <div>
            <p>
              Queremos que las plantas y flores, que son la materia prima de las cremas, se reflejen en la identidad gráfica de la marca.
              Para eso, creamos estas ilustraciones.
            </p>
          </div>
        </Container>
        <Container
          flexDirection="column"
          justifyContent="flex-start"
          align='flex-start'
          rowGap={0}>
          <div></div>
        </Container>
      </Container>
      <br/>
      <br/>
      <LazyLoad height="50vh" offset={100} style={{ fontSize: 0 }}>
        <Image imgSrc={images[3].src} imgAlt={images[3].title} disableLazyLoad={true}
          animationDirection={AnimationDirection.Up}/>
      </LazyLoad>
      <LazyLoad height="50vh" offset={100} style={{ fontSize: 0 }}>
        <Image imgSrc={images[4].src} imgAlt={images[4].title} disableLazyLoad={true}
          animationDirection={AnimationDirection.Up}/>
      </LazyLoad>
      {/* Columns wrapper */}
      <Container flexDirection="row" align="flex-start" responsive={true} columnGap={2.5} rowGap={0}>
        {/* Columna Izquierda */}
        <Container
          flexDirection="column"
          justifyContent="flex-start"
          align='flex-start'
          rowGap={0}>
          <Heading
            alignment="left"
            className="project-details__body__heading"
            label="Colores"
            level={2}
            marginBottom={1}
          />
          <div>
            <p>
              La paleta de colores representa cada uno de los aspectos más importantes de la marca.
            </p>
          </div>
        </Container>
        <Container
          flexDirection="column"
          justifyContent="flex-start"
          align='flex-start'
          rowGap={0}>
          <div></div>
        </Container>
      </Container>
      <div className="project-details__body__images">
        <Image imgSrc={images[5].src} imgAlt={images[5].title} animationDirection={AnimationDirection.Up}/>
        <Separator marginTop={1} marginBottom={1}></Separator>
        <Image imgSrc={images[6].src} imgAlt={images[6].title} animationDirection={AnimationDirection.Up}/>
        <Separator marginTop={1} marginBottom={1}></Separator>
        <Image imgSrc={images[7].src} imgAlt={images[7].title} animationDirection={AnimationDirection.Up}/>
        <Separator marginTop={1} marginBottom={1}></Separator>
        <Image imgSrc={images[8].src} imgAlt={images[8].title} animationDirection={AnimationDirection.Up}/>
        <Separator marginTop={1} marginBottom={1}></Separator>
        <Image imgSrc={images[9].src} imgAlt={images[9].title} animationDirection={AnimationDirection.Up}/>
        <Separator marginTop={1} marginBottom={1}></Separator>
        <Image imgSrc={images[10].src} imgAlt={images[10].title} animationDirection={AnimationDirection.Up}/>
        <Separator marginTop={1} marginBottom={1}></Separator>
        <Image imgSrc={images[11].src} imgAlt={images[11].title} animationDirection={AnimationDirection.Up}/>
        <Separator marginTop={1} marginBottom={1}></Separator>
        <Image imgSrc={images[12].src} imgAlt={images[12].title} animationDirection={AnimationDirection.Up}/>
        <Separator marginTop={1} marginBottom={1}></Separator>
        <Image imgSrc={images[13].src} imgAlt={images[13].title} animationDirection={AnimationDirection.Up}/>
        <Separator marginTop={1} marginBottom={1}></Separator>
        <Image imgSrc={images[14].src} imgAlt={images[14].title} animationDirection={AnimationDirection.Up}/>
      </div>
    </ProjectDetail>
  );
}
